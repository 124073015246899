import 'ol/ol.css';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import LineString from 'ol/geom/LineString';
import Point from 'ol/geom/Point';
import Circle from 'ol/geom/Circle';
import Map from 'ol/Map';
import {OSM, Vector as VectorSource} from 'ol/source';
import Stamen from 'ol/source/Stamen';
import View from 'ol/View';
import {Circle as CircleStyle, RegularShape, Stroke, Style, Icon, Text, Fill} from 'ol/style';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import {getVectorContext} from 'ol/render';
import {get, fromLonLat} from 'ol/proj';
import {getWidth} from 'ol/extent';
import {Rotate, Attribution, Control, defaults as defaultControls} from 'ol/control';
import {getArea, getLength} from 'ol/sphere';
import GeoJSON from 'ol/format/GeoJSON';
import Draw from 'ol/interaction/Draw';

class RotateNorthControl extends Control {
  /**
   * @param {Object} [opt_options] Control options.
   */
  constructor(opt_options) {
    const options = opt_options || {};

    const button = document.createElement('button');
    button.innerHTML = 'N';

    const element = document.createElement('div');
    element.className = 'rotate-north ol-unselectable ol-control';
    element.appendChild(button);

    super({
      element: element,
      target: options.target,
    });

    button.addEventListener('click', this.handleRotateNorth.bind(this), false);
  }

  handleRotateNorth() {
    this.getMap().getView().setRotation(0);
  }
}

const attribution = new Attribution({
  collapsible: true,
});

const stamen = new TileLayer({
  source: new Stamen({
    layer: 'toner',
  }),
});

const terrain = new TileLayer({
	preload: Infinity,
	source: new Stamen({
		layer: 'terrain',
	}),
});

const osm = new TileLayer({
  source: new OSM(),
});

let tileLayer = terrain;

const wpStyle = function(feature) {
	return new Style({
		image: new Icon({
			src: './res/svg/Circle_location.png',
			scale: 0.4,
		}),
/*		text: new Text({
			text: feature.get('name'),
			font: "1em sans-serif",
			offsetY: 20,
			textAlign: "center",
			fill: new Fill({
				color: "black"
			}),
		}),*/
	});
}
/*
const apStyle = function(feature) {
        return new Style({
                image: new RegularShape({
                        fill: new Fill({ color: "darkorange" }),
                        stroke: new Stroke({color: 'darkorange', width: 1}),
                        points: 3,
                        radius: 14,
                        rotation: 0,
                        angle: 0,
                }),
                text: new Text({
                        text: feature.get('name'),
                        font: "bold 2em sans-serif",
                        offsetY: 25,
                        textAlign: "center",
                        fill: new Fill({
                                color: "darkorange"
                        }),
                }),
        });
}
*/

const apStyle = function(feature) {
        return new Style({
		image: new Icon({
			src: './res/svg/Airport.png',
			//size: [1000, 1000],
			offset: [0, 15],
			//opacity: 1,
			scale: 0.6,
			//color: "rgba( 5, 22, 77, 1)"
                }),
                text: new Text({
                        text: feature.get('name'),
                        font: "bold 1.05em sans-serif",
                        offsetY: 30,
                        textAlign: "center",
                        fill: new Fill({
                                color: "rgba( 5, 22, 77, 1)"
                        }),
                }),
        });
}

const all_styleFunction = function (feature) {

	const text = feature.get('AV_NAME');

	const all_styles = {
		'MultiPolygon': new Style({
			stroke: new Stroke({
				color: 'rgba( 5, 22, 77, 1)',
				width: 1,
			}),
		}),
		'Polygon': new Style({
			stroke: new Stroke({
				color: 'rgba( 5, 22, 77, 1)',
				lineDash: [4],
				width: 2,
			}),
/*			text : new Text({
				text: text,
				font: '12pt sans-serif',
				stroke: new Stroke({
					color: 'blue',
					width: 0.75
				}),
			}),
			*/
		}),
	}

	return all_styles[feature.getGeometry().getType()];
};

const image = new CircleStyle({
  radius: 5,
  fill: null,
  stroke: new Stroke({color: 'red', width: 1}),
});

const waypointSource = new VectorSource({
	attributions: 'Lufthansa Data',
	loader: function () {
		const url = 'waypoints.json';
		console.log("waypoints loaded");
		fetch(url)
			.then(function (response) {
				return response.json();
			})
			.then(function (json) {
				for (let i = 0; i < json.length; i++) {
					const name = json[i].name;
					const lat = json[i].lat;
					const lon = json[i].lon;

					const features = [];

					const wp = new Point(fromLonLat([lon, lat]))

					//wp.transform('EPSG:4326', 'EPSG:3857');

					features.push(
						new Feature({
							geometry: wp,
							name: name,
						})
					);

					addWaypoints(features, i * 10);
				}
			});
	},
});

var waypointLayer = new VectorLayer({
	source: waypointSource,
	style:  wpStyle,
});

const airportSource = new VectorSource({
	attributions: 'Lufthansa Data',
	loader: function () {
		const url = 'airports.json';
		console.log("airports loaded");
		fetch(url)
			.then(function (response) {
				return response.json();
			})
			.then(function (json) {
				for (let i = 0; i < json.length; i++) {
					const name = json[i].name;
					const lat = json[i].lat;
					const lon = json[i].lon;

					const features = [];

					const wp = new Point(fromLonLat([lon, lat]))

					//wp.transform('EPSG:4326', 'EPSG:3857');

					features.push(
						new Feature({
							geometry: wp,
							name: name,
						})
					);
					//console.log(features);

					addAirports(features, i * 20);
				}
			});
	},
});

var airportLayer = new VectorLayer({
	source: airportSource,
	style: apStyle, 
});

const airspaceSource = new VectorSource({
	attributions: 'Eurostat',
	loader: function () {
		const url = 'airspace.json';
		console.log("airspace loaded");
		fetch(url)
			.then(function (response) {
				return response.json();
			})
			.then(function (json) {
				const features = new GeoJSON().readFeatures(json);
				const filtered_features = [];
				console.log(features);
				for(let i = 0;i<features.length;i++) {
					let text = features[i].get('AV_NAME');
					console.log(text);
					if(text) {
					let fir = text.includes("FIR");
					if(!fir) {
						filtered_features.push(features[i]);
					}
					}
				}
				console.log(filtered_features);
				addAirspace(filtered_features, 500);
			});
	},
});

var airspaceLayer = new VectorLayer({
	source: airspaceSource,
	style: all_styleFunction,
});


var routeFeatures = [];

const routesSource = new VectorSource({
	attribution: 'Lufthansa',
	loader: function () {
		const url = 'routes.json';
		console.log('routes loaded');
		fetch(url)
			.then(function (response) {
				return response.json();
			})
			.then(function (json) {
				let colors = ['rgba(15,102,76,0)','rgba(53,136,201,0)'];
				let elements = ['data_real','data_optimal'];
				for( let i = 0; i < json.length; i++) {
					let length = 0;
					for(let n = 1; n < json[i].length; n++) {
						let x = n - 1;
						let start = fromLonLat([json[i][x].lon,json[i][x].lat]);
						let end = fromLonLat([json[i][n].lon,json[i][n].lat]);
						let coords = [start,end];
						let feature = new Feature({
							geometry: new LineString(coords),
							color: colors[i],
						});
						routeFeatures.push(feature);
						length = length + getLength(feature.getGeometry());
						routesSource.addFeature(feature);
					};

					let ele = document.getElementById(elements[i]);
					//console.log(ele);
					//console.log(length);
					ele.textContent = Math.round((length/100)/10);
				};
			});
	}
});

const routeStyler = function (feature) {

	const color = feature.get('color');

	const style = new Style({
		stroke: new Stroke({
			color: color,
			width: 5,
		}),
	});

	return style; 
};

var routesLayer = new VectorLayer({
	source: routesSource,
	style: routeStyler,
});

routesLayer.setVisible(true);

var routeFeatures2 = [];

const routesSource2 = new VectorSource({
	attribution: 'Lufthansa',
	loader: function () {
		const url = 'routes.json';
		console.log('routes loaded');
		fetch(url)
			.then(function (response) {
				return response.json();
			})
			.then(function (json) {
				let colors = ['rgba(15,102,76,1)','rgba(53,136,201,0)'];
				let elements = ['data_real','data_optimal'];
				for( let i = 0; i < json.length; i++) {
					let length = 0;
					for(let n = 1; n < json[i].length; n++) {
						let x = n - 1;
						let start = fromLonLat([json[i][x].lon,json[i][x].lat]);
						let end = fromLonLat([json[i][n].lon,json[i][n].lat]);
						let coords = [start,end];
						let feature = new Feature({
							geometry: new LineString(coords),
							color: colors[i],
						});
						routeFeatures2.push(feature);
						length = length + getLength(feature.getGeometry());
						routesSource2.addFeature(feature);
					};
				};
			});
	}
});

var routesLayer2 = new VectorLayer({
	source: routesSource2,
	style: routeStyler,
});

routesLayer2.setVisible(false);
//routesLayer.setVisible(false);
//
var realroute = document.getElementById('realroute');
realroute.addEventListener ("change", function () {
	routesLayer2.setVisible(true);
});

const flightsSource = new VectorSource({
  attributions:
    'Direct Route',
  loader: function () {
    const url = 'flights.json';
    console.log("flights loaded");
    fetch(url)
      .then(function (response) {
        return response.json();
      })
      .then(function (json) {
        const flightsData = json.flights;
        for (let i = 0; i < flightsData.length; i++) {
          const flight = flightsData[i];
          const from = flight[0];
          const to = flight[1];

          // create an arc circle between the two locations
          const arcGenerator = new arc.GreatCircle(
            {x: from[1], y: from[0]},
            {x: to[1], y: to[0]}
          );

          const arcLine = arcGenerator.Arc(100, {offset: 10});
          // paths which cross the -180°/+180° meridian are split
          // into two sections which will be animated sequentially

          const features = [];
          arcLine.geometries.forEach(function (geometry) {
            const line = new LineString(geometry.coords);
            line.transform('EPSG:4326', 'EPSG:3857');

	    const llength = getLength(line);
		let direct =document.getElementById('data_optimal');
		  direct.textContent = Math.floor(llength/1000);

            features.push(
              new Feature({
                geometry: line,
                finished: false,
		length: llength,
              })
            );
          });
          // add the features with a delay so that the animation
          // for all features does not start at the same time
          //addLater(features, i * 50);
	flightsSource.addFeatures(features);
        }
        //tileLayer.on('postrender', animateFlights);
      });
  },
});

const style = new Style({
  stroke: new Stroke({
    color: 'rgba(53,136,201,0)',
    width: 5,
  }),
});

const flightsLayer = new VectorLayer({
  source: flightsSource,
  style: function (feature) {
      return style;
    },
});

flightsLayer.setVisible(true);

const flightsSource2 = new VectorSource({
  attributions:
    'Direct Route',
  loader: function () {
    const url = 'flights.json';
    console.log("flights loaded");
    fetch(url)
      .then(function (response) {
        return response.json();
      })
      .then(function (json) {
        const flightsData = json.flights;
        for (let i = 0; i < flightsData.length; i++) {
          const flight = flightsData[i];
          const from = flight[0];
          const to = flight[1];

          // create an arc circle between the two locations
          const arcGenerator = new arc.GreatCircle(
            {x: from[1], y: from[0]},
            {x: to[1], y: to[0]}
          );

          const arcLine = arcGenerator.Arc(100, {offset: 10});
          // paths which cross the -180°/+180° meridian are split
          // into two sections which will be animated sequentially

          const features = [];
          arcLine.geometries.forEach(function (geometry) {
            const line = new LineString(geometry.coords);
            line.transform('EPSG:4326', 'EPSG:3857');

            features.push(
              new Feature({
                geometry: line,
                finished: false,
              })
            );
          });
          // add the features with a delay so that the animation
          // for all features does not start at the same time
          //addLater(features, i * 50);
	flightsSource2.addFeatures(features);
        }
        //tileLayer.on('postrender', animateFlights);
      });
  },
});

const style2 = new Style({
  stroke: new Stroke({
    color: 'rgba(53,136,201,1)',
    width: 5,
  }),
});

const flightsLayer2 = new VectorLayer({
  source: flightsSource2,
  style: function (feature) {
      return style2;
    },
});

flightsLayer2.setVisible(false);

var directroute = document.getElementById('directroute');
directroute.addEventListener ("change", function () {
	flightsLayer2.setVisible(true);
});

var userSource = new VectorSource({
});

const userStyle = new Style({
  stroke: new Stroke({
    color: 'rgba(207,50,49,1)',
    width: 5,
  }),
});

var userLayer = new VectorLayer({
	source: userSource,
	style: userStyle,
});

var draw = new Draw({
	source: userSource,
	type: 'LineString',
	freehand: true,
	style: new Style({
		image: new Icon({
			src: './res/svg/Airplane_cruising_LHG-yellow.png',
			scale: 0.4,
		}),
		stroke: new Stroke({
			color: 'rgba(207,50,49,1)',
			width: 4,
		}),
	}),
});

function setDebriefingValues() {
	let data_items = ['data_user','data_real','data_optimal'];
	let items = ['user','real','optimal'];
	for(let i = 0; i < items.length; i++) {
		let item = document.getElementById(data_items[i]);
		let deitem = document.getElementsByName(items[i]);
		addValues(deitem[0],item.textContent);
	};
}

function addValues(ele,dist) {
	console.log('dist='+dist);
	var newDiv = document.createElement("div");
	var newContent = document.createTextNode(Math.floor(kmToNm(dist)) + " nm");
	newDiv.classList.add('distance');
	newDiv.classList.add('top_border');
	newDiv.classList.add('col2');
	newDiv.appendChild(newContent);
	ele.appendChild(newDiv);
	var newDiv1 = document.createElement("div");
	var newContent1 = document.createTextNode(Math.floor(distToFuel(dist)) + " kg");
	newDiv1.classList.add('fuel');
	newDiv1.classList.add('top_border');
	newDiv1.classList.add('col3');
	newDiv1.appendChild(newContent1);
	ele.appendChild(newDiv1);
	var newDiv2 = document.createElement("div");
	var newContent2 = document.createTextNode(Math.floor(fueltoCO2(distToFuel(dist))) + " kg");
	newDiv2.classList.add('co2');
	newDiv2.classList.add('top_border');
	newDiv2.classList.add('col4');
	newDiv2.appendChild(newContent2);
	ele.appendChild(newDiv2);
}

function My_prepend(value, array) {
  var newArray = array.slice();
  newArray.unshift(value);
  return newArray;
};

function kmToNm(dist) {
	let nm = (dist/1.852);
	return nm;
}

function distToFuel(dist) {
	let fuel = (dist/1.852)*4.2;
	return fuel;
}

function distToCO2(dist) {
	let coo = (dist/1.852)*3.15;
	return coo;
}

function fueltoCO2(fuel) {
	let coo = Math.floor((fuel*3.15)*10)/10;
	return coo;
}

draw.on('drawend', function(evt){
	let hints = document.getElementById('hints');
	hints.style.transition = 'visibility 0s linear 0.8s, opacity 0.53s linear';
	hints.style.opacity = '0';
	hints.style.visibility = 'hidden';
	evt.feature.getGeometry().appendCoordinate(fromLonLat([1.373117,38.872858]));
	var coordinates = evt.feature.getGeometry().getCoordinates();
	console.log(coordinates);
	var new_coords = My_prepend(fromLonLat([11.786086,48.353783]),coordinates);
	console.log(new_coords);
	evt.feature.getGeometry().setCoordinates(new_coords,'XY');
	var length = getLength(evt.feature.getGeometry());
	let ele = document.getElementById('data_user');
	ele.textContent = Math.round((length/100)/10);
	evt.feature.set('finished',1);
	map.removeInteraction(draw);
	window.setTimeout(function () {
		view.animate({zoom: 6.2, rotation: 0 , center: fromLonLat([16.5,43.56])}, animateCallback);
	},350);
	//	setDebriefingValues();
});

function animateCallback (complete) {
	if (complete) {
			window.setTimeout(function () {
		toggleDebriefing();
		setDebriefingValues()

	},750);
  	window.setTimeout(function () {
		let teasertext = document.getElementsByClassName('compare_text');
		teasertext[0].style.visibility = 'visible';
		teasertext[0].style.opacity = '1';
	},1000);
}
}

const view = new View({
	center: fromLonLat([7.4, 43.5]),
	zoom: 2,
	rotation: 0,
}); 

/*
const rotate = new Rotate({
	target:
});
*/

const map = new Map({
	layers: [tileLayer, routesLayer, routesLayer2, flightsLayer, flightsLayer2, airspaceLayer, airportLayer, userLayer, waypointLayer],
	target: 'map',
	view: view,
	controls: [],
});

let north = document.getElementById('north');
console.log(north);
north.addEventListener('click', function () {
	view.animate({zoom: 5.8, rotation: 0});
});
console.log(north);

var mission = 0;

function startMission() {
	if(mission) {
		document.location.replace(document.location.protocol + '//' + document.location.host+'/');	
	} else {
		mission = 1;
		//toggleMission();
		window.setTimeout(function () {
			map.addInteraction(draw);
			view.animate({zoom: 6.5, rotation: 0.55 , center: fromLonLat([7.4,43.5])});
		},350);
	}
}


window.setTimeout(function () {
	startMission();
},800);

function addWaypoints(features, timeout) {
	window.setTimeout(function () {
	    features.forEach(function (feature) {
		    waypointSource.addFeature(feature);	
	    });
	}, timeout);
}

function addAirports(features, timeout) {
	window.setTimeout(function () {
	    features.forEach(function (feature) {
		    airportSource.addFeature(feature);	
	    });
	}, timeout);
}

function addAirspace(features, timeout) {
	window.setTimeout(function () {
	    features.forEach(function (feature) {
		    //console.log('addAirspace');
		    airspaceSource.addFeature(feature);	
	    });
	}, timeout);
}

